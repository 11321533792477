/* eslint-disable */
export const faqCategories = [
    {
        id: '28924eab-97cc-465a-ba21-f232bb95843f',
        slug: 'employer-most-frequent',
        title: 'Most Frequently Asked Questions'
    },
    {
        id: 'ebcfe17f-4ae7-41d9-85d1-0cd37309a735',
        slug: 'employer-finding-talent',
        title: 'Finding Talent on JobzMall'
    },
    {
        id: 'd2159386-7df3-47f1-9137-bc7cdee5f932',
        slug: 'employer-integrations',
        title: 'Integrations'
    },
    {
        id: '29d7102f-f35a-4f74-805b-4f8e0c0f0a81',
        slug: 'employer-security',
        title: 'Security'
    },
    {
        id: 'fdad3c69-3a69-4ef0-83fa-7a92438a6a6a',
        slug: 'employer-about',
        title: 'About JobzMall'
    },
    {
        id: 'b6580fc8-f7fe-40f1-b012-acac78b8d862',
        slug: 'coach-most-asked',
        title: 'Most Frequently Asked Questions'
    },
    {
        id: '31549dc1-d62b-4883-aab7-db15929240bb',
        slug: 'coach-setting-up',
        title: 'Setting up your Account'
    },
    {
        id: '8fdceaa3-e964-48c5-a5f9-db49c749e61c',
        slug: 'coach-integrations',
        title: 'Integrations'
    },
    {
        id: 'a6d0aefe-5837-4d6a-86e3-f0e9496f998a',
        slug: 'coach-managing-clients',
        title: 'Managing your Clients'
    },
    {
        id: '84742287-0780-4c0f-8fa9-055a19bd7e27',
        slug: 'coach-payments',
        title: 'Payments'
    },
    {
        id: 'd30a0fd3-5947-49ef-a083-e7c80eb620a5',
        slug: 'coach-about',
        title: 'About JobzMall'
    },

    {
        id: '41468568-ca34-43d2-a7f5-2dad2e83258c',
        slug: 'user-most-asked',
        title: 'Most Frequently Asked Questions'
    },
    {
        id: '5cb6a4c7-ccfa-4b44-ad52-55caaf77187c',
        slug: 'user-managing-profile',
        title: 'Managing your Profile'
    },
    {
        id: '33aa005a-2cac-4956-b6f8-d312d1397012',
        slug: 'user-managing-account',
        title: 'Managing your Account'
    },
    {
        id: '44b1fc5e-78a6-4df8-b660-3b31cd31d717',
        slug: 'user-managing-jobs-and-apps',
        title: 'Jobs and Applications'
    },
    {
        id: '75a31afd-c19a-4952-861f-06f19268aee3',
        slug: 'user-calendar',
        title: 'Calendar'
    },
    {
        id: 'cb5dd52a-0556-4460-9ebc-79f6f0fae42a',
        slug: 'user-genius-academy',
        title: 'Genius and Academy'
    },
    {
        id: 'b830bc65-0ad9-4f96-aae2-af31b2e86459',
        slug: 'user-about-jobzmall',
        title: 'About JobzMall'
    }
];
export const faqs = [
    // User: FAQ
    {
        id: 'f65d517a-6f69-4c88-81f5-416f47405ce1',
        categoryId: '41468568-ca34-43d2-a7f5-2dad2e83258c',
        question: 'What is JobzMall?',
        answer: 'JobzMall is the world’s largest video-based talent marketplace, connecting career seekers with opportunities. As a career-seeker first platform, our intention is to empower you with the leading technology to help you navigate your career and help you pursue your potential.<br><br>Whether it’s applying to jobs with Video Resume and Video Cover Letter, or finding a career coach for you to reach your goals, JobzMall is there to help you.<br><br>Our movement is pioneering the talent marketplace and how talent meets work. It’s faster, smarter, and more human.'
    },
    {
        id: '0fcece82-1691-4b98-a9b9-b63218f9deef',
        categoryId: '41468568-ca34-43d2-a7f5-2dad2e83258c',
        question: 'Who can see my profile?',
        answer: 'Your profile is accessible depending on your privacy settings. You have a few options.<br><br><ul class="space-y-4"><li><strong>Public</strong> Your profile will be visible to anyone, in accordance with our terms. Your phone number and email address are only provided to employers you apply to. Your street address is only visible to you. This option gives you the maximum exposure while retaining your core privacy in protection. Your Profile will have this option by default.</li><li><strong>Employers-Only</strong> Your profile will not be visible to the public, only employers on JobzMall will be able to view your profile on our Talent Bank Employers won’t be able to find your profile. Only the employers you apply to will see your information.</li> <li><strong>Private</strong> Your profile will not be visible. Employers won’t be able to find your profile. Only the employers you apply to will see your information. Maximum privacy. This option is only favorable if you are targeting a specific employer.</li> </ul>'
    },
    {
        id: '2e6971cd-49d5-49f1-8cbd-fba5c71e6062',
        categoryId: '41468568-ca34-43d2-a7f5-2dad2e83258c',
        question: 'What data is shared with Employers?',
        answer: 'Employers that you have applied to will access all of your work history, the resume and cover letter that you have shared with them, and your contact information. We will rescind such access if you withdraw your application, or if they close the job opening.'
    },
    {
        id: '974f93b8-336f-4eec-b011-9ddb412ee828',
        categoryId: '41468568-ca34-43d2-a7f5-2dad2e83258c',
        question: 'What is a Video Resume?',
        answer: 'A Video Resume is a revolutionary video-based form of expression for you as an individual and candidate. Essentially, we ask targeted questions that your peers and employers are interested in hearing. However, the magic is in your response. Because potential employers and peers get to see and interact with you via video, people get to see who you are and what is important to you from the get-go.'
    },
    {
        id: '5d877fc7-b881-4527-a6aa-d39d642feb23',
        categoryId: '41468568-ca34-43d2-a7f5-2dad2e83258c',
        question: 'Do I have to pay anything?',
        answer: 'No, JobzMall is free for job seekers. You can purchase services such as working with a Career Coach or a Certificate Courses which may have their own pricing.'
    },
    {
        id: '5d877fc7-b881-4527-a6aa-d39d642feb24',
        categoryId: '41468568-ca34-43d2-a7f5-2dad2e83258c',
        question: 'How do I delete my JobzMall Account?',
        answer: 'To delete your JobzMall account, you need to deactivate your profile. Upon deactivation, your account access and any data associated with your account will be removed. Deactivated accounts are scheduled for full deletion and termination automatically per our data retention policy.'
    },
    // User: Managing Profile
    {
        id: 'ac180f22-acd9-44ba-816e-9facf5c6fc66',
        categoryId: '5cb6a4c7-ccfa-4b44-ad52-55caaf77187c',
        question: 'How to Upload a Profile Picture?',
        answer: 'JobzMall has a built-in Image Editor to edit and maximize the impact of your profile picture. Simply go to your profile, hover over the profile image and hit the camera icon to get started.'
    },
    {
        id: '7650e18c-0649-4cad-97f7-1a5c26c4841e',
        categoryId: '5cb6a4c7-ccfa-4b44-ad52-55caaf77187c',
        question: 'How can I manage my privacy settings?',
        answer: 'You can manage your profile privacy settings by navigating to user settings. Your profile has a few different privacy options. <br><br><ul class="space-y-4"><li><strong>Public</strong> Your profile will be visible to anyone, in accordance with our terms. Your phone number and email address are only provided to employers you apply to. Your street address is only visible to you. This option gives you the maximum exposure while retaining your core privacy in protection. Your Profile will have this option by default.</li><li><strong>Employers-Only</strong> Your profile will not be visible to the public, only employers on JobzMall will be able to view your profile on our Talent Bank Employers won’t be able to find your profile. Only the employers you apply to will see your information.</li> <li><strong>Private</strong> Your profile will not be visible. Employers won’t be able to find your profile. Only the employers you apply to will see your information. Maximum privacy. This option is only favorable if you are targeting a specific employer.</li> </ul>'
    },
    {
        id: '2d012841-7e93-4615-99ad-28f2e50ab3ee',
        categoryId: '5cb6a4c7-ccfa-4b44-ad52-55caaf77187c',
        question: 'Who can see my profile?',
        answer: 'You are in complete control of who gets to see your profile. By default, your profile is seen by everyone, but not your sensitive data such as email address and phone number. To further fine tune your privacy options simply go to your Privacy Settings.'
    },
    {
        id: 'f1fe1537-ec17-4eb9-bebc-d3e9d46fa916',
        categoryId: '5cb6a4c7-ccfa-4b44-ad52-55caaf77187c',
        question: 'How can I upload and manage resumes?',
        answer: 'At JobzMall, you can upload and manage different Resumes for different applications. You also have the option to have a free Expert Resume Feedback complimentary by JobzMall.'
    },
    {
        id: '0c71fe61-0983-4591-b81b-50ef63fc2823',
        categoryId: '5cb6a4c7-ccfa-4b44-ad52-55caaf77187c',
        question: 'How can I connect my other social links such as LinkedIn?',
        answer: "You can connect your social links, profiles, and portfolios on JobzMall, to fully encapsulate your full digital identity. Simply navigate to your profile, and hit 'Edit Profile' > 'Manage Social Links'."
    },
    // User: Managing Account
    {
        id: '4ccd14fd-40e6-4b34-854c-70f85c6a1135',
        categoryId: '33aa005a-2cac-4956-b6f8-d312d1397012',
        question: 'How can I deactivate my account?',
        answer: 'You can deactivate your account on your profile settings. We highly encourage you to update your profile privacy settings instead as deactivating your account will remove your profile and will cause you to miss out on new opportunities.'
    },
    {
        id: '5ee5dee9-10fd-4aca-8614-783ee0048795',
        categoryId: '33aa005a-2cac-4956-b6f8-d312d1397012',
        question: 'How can I change my email and phone number?',
        answer: 'You can change your email and phone number on your profile settings and following the instructions.'
    },
    // User: Jobs and Applications
    {
        id: '563e366e-4fc1-4a5c-b62d-8c0ff5572fff',
        categoryId: '44b1fc5e-78a6-4df8-b660-3b31cd31d717',
        question: 'How can I withdraw an application?',
        answer: 'Here at JobzMall, we have been asked this question many times. It is very common to want to withdraw your job or Direct application. Simply go to Jobs and navigate to ‘Applications’. Click on the Application you would like to withdraw and select ‘Actions’ and hit ‘Withdraw’.'
    },
    {
        id: '1e97df18-3876-4ba3-aa00-526ff60bdc2c',
        categoryId: '44b1fc5e-78a6-4df8-b660-3b31cd31d717',
        question: 'How can I track the status of an application?',
        answer: 'At JobzMall, you can track the status of your job applications the way you can track a delivery! Simply go to Jobs and navigate to ‘Applications’. Here you would have a list of all your active and archived applications. Click on the Application you would like to track the status of and see its progress. If you have any specific questions, do not hesitate to contact us.'
    },
    {
        id: 'fbd1aa4e-1337-4c5d-98d1-bfa2fb5f615d',
        categoryId: '44b1fc5e-78a6-4df8-b660-3b31cd31d717',
        question: 'Can I re-apply to a job that I have withdrawn earlier?',
        answer: 'Yes you absolutely can as long as the job is still active and you have already withdrawn your prior application.'
    },
    {
        id: 'da4e1b59-fcdd-4096-9101-cca7c8a13d84',
        categoryId: '44b1fc5e-78a6-4df8-b660-3b31cd31d717',
        question: 'What is Direct Apply?',
        answer: "Direct Apply is a revolutionary way to apply to companies developed by JobzMall. Direct applications allow you to apply directly to a company, even if they have no job openings.<br><br>JobzMall was founded on the core principle that you are more than a resume. You are a human being, with a special footprint on this world. We never believe that you should be disregarded just because a piece of paper wasn't formatted the right way, or even worse -- you have talents that a company isn't hiring for right now.<br><br>JobzMall Direct Apply fills the need for your true talent to be put in front of employers regardless of whether or not they have an opening for the talents you provide. We have found that this provides a huge opportunity for individuals and for companies that didn't even know they needed each other.<br><br>So next time you get down because your favorite company isn't hiring, think of JobzMall Direct Apply. They may just love you enough to bring you on anyway! At JobzMall, talent knows no bounds."
    },
    {
        id: '68dc2dc0-8261-46f1-9ffe-de4221571843',
        categoryId: '44b1fc5e-78a6-4df8-b660-3b31cd31d717',
        question: 'What are job expiration dates and why are they shifting?',
        answer: 'When employers post a job on JobzMall, it is typically displayed for 30 days by default. However, employers can choose to extend their job postings if the position is still actively hiring. Additionally, our platform includes an “auto-roll” feature, which keeps job postings active until explicitly removed by the employer or if the job posting violates our job posting guidelines.<br><br>We maintain strict policies against jobs that are not actively hiring and regularly review postings for compliance. If you encounter a job listing that appears inactive or misleading, please report it to us promptly, and we will investigate.'
    },
    // User: Calendar
    {
        id: 'e9549cb1-20f4-4dfa-b879-d5275b442c39',
        categoryId: '75a31afd-c19a-4952-861f-06f19268aee3',
        question: 'What is Calendar?',
        answer: 'At JobzMall, you can manage your interviews and meetings on your calendar. Additionally, you can integrate your Google Calendar and Microsoft Outlook Calendar to JobzMall to manage your meetings automatically across channels. If you need further help please do not hesitate to contact us with live chat and a success agent will be on to help you get you going!'
    },
    {
        id: '9de3ecb7-3015-4ea7-a251-bf1a53ea438f',
        categoryId: '75a31afd-c19a-4952-861f-06f19268aee3',
        question: 'Integrate with Google Calendar',
        answer: 'You can integrate your Google Calendar to JobzMall to manage your meetings automatically across channels. If you need further help please do not hesitate to contact us with live chat and a success agent will be on to help you get you going!'
    },
    {
        id: 'df507d73-237d-4560-8ab2-1c97714d4fa8',
        categoryId: '75a31afd-c19a-4952-861f-06f19268aee3',
        question: 'Integrate with Microsoft Outlook Calendar',
        answer: 'You can integrate your Microsoft Outlook Calendar to JobzMall to manage your meetings automatically across channels. If you need further help please do not hesitate to contact us with live chat and a success agent will be on to help you get you going!'
    },
    // User: Genius and Academy
    {
        id: 'c37d149c-91cd-4556-816b-9107f3e35408',
        categoryId: 'cb5dd52a-0556-4460-9ebc-79f6f0fae42a',
        question: 'What is Career Genius?',
        answer: 'Career Genius is our career coach marketplace, where you can find a coach to work with and grow in your career. Wherever you are in your career journey, your career genius is here to help. Connect with the best coaches in the world with JobzMall guarantee.'
    },
    {
        id: '5faf68bf-b3d2-4e9f-b181-948190862839',
        categoryId: 'cb5dd52a-0556-4460-9ebc-79f6f0fae42a',
        question: 'What is JobzMall Academy?',
        answer: 'We have partnered with the best training organizations for you to get the credentials you need to expand in your field. Advance your career with industry leading training, knowledge and resources. Get professional-level training and certifications with JobzMall guarantee.'
    },
    {
        id: 'c2b1d7b0-f6c6-40eb-b263-35bbd71b2a1b',
        categoryId: 'cb5dd52a-0556-4460-9ebc-79f6f0fae42a',
        question: 'Will I get a refund if there’s a problem?',
        answer: 'JobzMall is committed to ensuring your satisfaction with our service. If you encounter any issues or concerns regarding your purchase, please contact us immediately, and we will work with you to address and resolve the situation.'
    },
    // User: About JobzMall
    {
        id: '51348c15-a6b3-4d5d-95a1-177989025a16',
        categoryId: 'b830bc65-0ad9-4f96-aae2-af31b2e86459',
        question: 'Our mission at JobzMall',
        answer: 'JobzMall is built to make job finding accessible, fun, and human. We believe that everyone has a story to tell and there is a job for everyone in our society. We build and invest in tools to make you realize that mission.'
    },
    {
        id: 'c66e1a6f-9650-4a16-8202-f9d9464189a5',
        categoryId: 'b830bc65-0ad9-4f96-aae2-af31b2e86459',
        question: 'JobzMall Pricing',
        answer: 'JobzMall is absolutely free for job seekers. You can purchase services such as working with a Career Coach or a Certificate Courses which may have their own pricing.'
    },
    {
        id: '74d24e9e-c6a0-4e94-a7fc-a3a234f2a096',
        categoryId: 'b830bc65-0ad9-4f96-aae2-af31b2e86459',
        question: 'JobzMall Terms of Service',
        answer: 'You can review JobzMall’s Terms Of Service here: <a target="_blank" href="https://www.jobzmall.com/legal/terms">https://www.jobzmall.com/legal/terms</a> Please never hesitate to reach out to us with any questions.'
    },
    {
        id: '4176bd41-d382-4a2b-badd-77439424e869',
        categoryId: 'b830bc65-0ad9-4f96-aae2-af31b2e86459',
        question: 'How can I exercise my data privacy rights?',
        answer: 'JobzMall users are able to access most of their Personal Data held by JobzMall on the Site, and if you have any queries or complaints we encourage you to contact us by emailing <a href="mailto:privacy@jobzmall.com">privacy@jobzmall.com</a>'
    },

    // Coach: FAQ
    {
        id: '0a4e2ec6-e422-42ba-8b14-e7c54971c317',
        categoryId: 'b6580fc8-f7fe-40f1-b012-acac78b8d862',
        question: 'Can anyone become a JobzMall Genius?',
        answer: 'At JobzMall, we strive to build the best talent marketplace in the world. That is why we vet not just the identity but the expertise of our Career Coaches. There is a reason why we call them Career Geniuses. If you are a premier career coach, this is your home.'
    },
    {
        id: 'af4ed9da-7d06-4a05-98c2-5ff544eecb2a',
        categoryId: 'b6580fc8-f7fe-40f1-b012-acac78b8d862',
        question: 'How much can I make?',
        answer: 'We have some Geniuses that sell out within a few minutes and some that get a few bookings each month. Based on our data, we believe that Geniuses will have the opportunity to generate up to $70k+ in additional yearly revenue.'
    },
    {
        id: 'd4d922eb-3620-4d73-b0a5-8954ae30f38b',
        categoryId: 'b6580fc8-f7fe-40f1-b012-acac78b8d862',
        question: 'How long are the sessions?',
        answer: 'Geniuses can choose to offer sessions ranging from 15 minutes to 1 hour. Most geniuses choose to offer between 15-60 minutes. Seamlessly connect with our Video Rooms or with tools you already used to like Zoom or Teams.'
    },
    {
        id: 'b3fb0b24-5c40-4be7-a9e4-a2735a775b97',
        categoryId: 'b6580fc8-f7fe-40f1-b012-acac78b8d862',
        question: 'Is there a minimum time commitment?',
        answer: "You can add as many sessions as you'd like. There are no minimums or maximums. We have some Geniuses that offer one hour per month and others that offer 50+ hours per month."
    },
    {
        id: '5a123caa-0984-484b-8516-003eb9709326',
        categoryId: 'b6580fc8-f7fe-40f1-b012-acac78b8d862',
        question: 'Can international customers book me?',
        answer: 'Yes. We accept international customers which allows people from all over the world to access in-demand career coaches like yourself.'
    },
    {
        id: 'bd478e09-42ac-462d-a661-f6f24911b771',
        categoryId: 'b6580fc8-f7fe-40f1-b012-acac78b8d862',
        question: 'Do I have to pay a subscription fee to join JobzMall?',
        answer: 'JobzMall is free to join. We only make money when our Geniuses do and we also believe in a fair pricing model. For booking through our marketplace, we ask for a 20% introductory commission. For bookings with existing clients, we ask for a 10% commission.'
    },
    // Coach: Setting up your account
    {
        id: '99ed9326-178e-4ee4-9ba5-cd74a0236d7d',
        categoryId: '31549dc1-d62b-4883-aab7-db15929240bb',
        question: 'Creating your Profile',
        answer: 'JobzMall’s talent marketplace nurtures the world’s most engaged and qualified career seekers that are looking to grow.<br><br>As a Coach, you will have access to a wide range of tools that are built to help you fuel your business while sharing your value, knowledge and services with our community.<br><br>We highly encourage you to fill your profile with a Video, with your work experience, and services you offer.'
    },
    {
        id: '61a47b1c-ddd5-4098-ade0-b9cc8e5d21f0',
        categoryId: '31549dc1-d62b-4883-aab7-db15929240bb',
        question: 'Managing your Availability',
        answer: 'We’ve streamlined orders with automated scheduling so that you do not have to worry about Scheduling Meetings. Simply connect your Calendars and your customers will be only able to book sessions on your availability. You will still have the option to cancel and reschedule if a conflict arises.'
    },
    {
        id: '361c60be-c2e4-4db3-aaf0-23250a27d3f6',
        categoryId: '31549dc1-d62b-4883-aab7-db15929240bb',
        question: 'Connect your Calendars',
        answer: 'You can integrate your Google Calendar and Microsoft Calendar to JobzMall to manage your meetings automatically across channels. If you need further help please do not hesitate to contact us with live chat and a success agent will be on to help you get you going!'
    },
    {
        id: '89210fc4-dacd-4845-90a4-01e48e4cf557',
        categoryId: '31549dc1-d62b-4883-aab7-db15929240bb',
        question: 'Set up your Bank Account',
        answer: "In order to start processing payments, you'll need to link your business or personal bank account. Please connect the account where you'd like us to transfer your funds. We have partnered with Stripe and PayPal for payment processing, and Stripe for Bank payments. If you need any help please contact us."
    },
    // Coach: Integrations
    {
        id: '4a86de8f-f631-463f-8fa7-6e218d080b6e',
        categoryId: '8fdceaa3-e964-48c5-a5f9-db49c749e61c',
        question: 'Google Calendar',
        answer: 'You can integrate your Google Calendar to JobzMall to manage your meetings automatically across channels. If you need further help please do not hesitate to contact us with live chat and a success agent will be on to help you get you going!'
    },
    {
        id: '8d6d8641-8cc2-4ca1-b815-70cba3787799',
        categoryId: '8fdceaa3-e964-48c5-a5f9-db49c749e61c',
        question: 'Microsoft Outlook Calendar',
        answer: 'You can integrate your Microsoft Outlook Calendar to JobzMall to manage your meetings automatically across channels. If you need further help please do not hesitate to contact us with live chat and a success agent will be on to help you get you going!'
    },
    {
        id: 'bdedbedc-cddf-47e1-9d88-cdb959b25b2e',
        categoryId: '8fdceaa3-e964-48c5-a5f9-db49c749e61c',
        question: 'ATS Options',
        answer: 'JobzMall currently integrates with four ATS partners: Greenhouse, Lever and Workable, and Workday. Please Integrations Center for more details. If you need further help or need an integration with a different vendor, do not hesitate to contact us as we will be happy to assist you.'
    },
    {
        id: 'c3f774cd-d5f9-4783-9fe9-a770326068d2',
        categoryId: '8fdceaa3-e964-48c5-a5f9-db49c749e61c',
        question: 'Zoom Video',
        answer: 'As the world’s largest video-first talent marketplace, JobzMall integrates seamlessly with Zoom to have you manage your video meetings. You can integrate your Zoom account to JobzMall to manage your meetings automatically across channels. If you need further help please do not hesitate to contact us with live chat and a success agent will be on to help you get you going!'
    },
    // Coach: Clients
    {
        id: '5b873566-59a8-4b78-b38e-258a88d202e8',
        categoryId: 'a6d0aefe-5837-4d6a-86e3-f0e9496f998a',
        question: 'How to promote your profile?',
        answer: 'Your profile will be shared across our 4M+ members on Career Genius optimized for the best results. We also highly encourage you to share your JobzMall profile on your social media platforms to boost your visibility. If you need further help we highly encourage you to contact us.'
    },
    {
        id: '4c36ede9-eb31-4261-a3ce-ab53ba2b6a31',
        categoryId: 'a6d0aefe-5837-4d6a-86e3-f0e9496f998a',
        question: 'Managing your Customers and Orders',
        answer: 'Once you receive an order, you will have the chance to accept or decline the order. You will also have the ability to directly message them, schedule video meetings, and send questionnaires.'
    },
    {
        id: '16f417bc-786c-4953-9998-69f359743215',
        categoryId: 'a6d0aefe-5837-4d6a-86e3-f0e9496f998a',
        question: 'Managing Meetings',
        answer: 'You can integrate your Microsoft Outlook or Google Calendars to JobzMall to manage your meetings automatically across channels. If you need further help please do not hesitate to contact us with live chat and a success agent will be on to help you get you going!'
    },
    // Coach: Payments
    {
        id: '140b111a-251f-4bd4-8084-134d99ef28fa',
        categoryId: '84742287-0780-4c0f-8fa9-055a19bd7e27',
        question: 'How to Connect your Bank Account?',
        answer: "In order to start processing payments, you'll need to link your business or personal bank account. Please connect the account where you'd like us to transfer your funds. We have partnered with Stripe and PayPal for payment processing, and Stripe for Bank payments. Simply follow the steps that Stripe requires you to fill. If you need any help please contact us."
    },
    {
        id: '9defddc8-9e1d-494d-829c-b740034dcdf3',
        categoryId: '84742287-0780-4c0f-8fa9-055a19bd7e27',
        question: 'How do payouts work?',
        answer: 'A customer can only book you by paying upfront. It is our responsibility to process the payment and hold on to the money until the service is complete.'
    },
    {
        id: 'de51a79b-a389-4dd3-847f-bd252afe14e0',
        categoryId: '84742287-0780-4c0f-8fa9-055a19bd7e27',
        question: 'When will I get paid?',
        answer: 'Once you complete an order, we will dispatch your pay out automatically after 1-3 business days.'
    },

    // Employer: About
    {
        id: 'd860d1ef-4331-4511-b159-9b995a32200a',
        categoryId: 'd30a0fd3-5947-49ef-a083-e7c80eb620a5',
        question: 'Benefits of JobzMall',
        answer: 'JobzMall is the simplest, most efficient way to hire the best talent. We provide you access to a highly qualified pool of candidates — both full-time and contract — that are ready to interview now. Each of these individuals are considering new roles and have been screened through a combination of proprietary algorithms and an in-house curation team.<br><br>Our platform gives you insight into candidates’ professional background, work preferences, and compensation, so you have the information you need to make better decisions, adjust your offers in real-time if needed, and hire the best fit for your team.'
    },
    {
        id: '82f602c5-88b7-4216-9af2-712caa057570',
        categoryId: 'd30a0fd3-5947-49ef-a083-e7c80eb620a5',
        question: 'JobzMall Pricing',
        answer: "Whether you're hiring two or two hundred, JobzMall will help you address your unique hiring needs. Your Account Manager will discuss the best option with you and custom pricing is also available.<br><br>JobzMall is free for candidates to use."
    },
    {
        id: 'ba1f3703-2e32-4b94-848b-bb97015aa064',
        categoryId: 'd30a0fd3-5947-49ef-a083-e7c80eb620a5',
        question: 'JobzMall Terms of Service',
        answer: 'You can review JobzMall’s Terms Of Service here: <a href="https://www.jobzmall.com/legal/terms" target="_blank">https://www.jobzmall.com/legal/terms<a><br><br>Please never hesitate to reach out to us with any questions.'
    },
    {
        id: '1e164728-3343-4cdb-9ab2-e7c386ffab77',
        categoryId: 'd30a0fd3-5947-49ef-a083-e7c80eb620a5',
        question: 'JobzMall Compliance',
        answer: 'JobzMall System and Organization Controls (SOC) Reports are independent third-party examination reports that demonstrate how Hired achieves key compliance controls and objectives. The purpose of these reports is to help you and your compliance team understand the JobzMall controls pertaining to security processes and the platform. A mutual non-disclosure agreement (MNDA) is required to review the JobzMall SOC 2 Report. If you would like to learn more or have an interest in reviewing the complete Report, contact us.'
    },

    // Employer: FAQ
    {
        id: 'f65d517a-6f69-4c88-81f5-416f47405ce1',
        categoryId: '28924eab-97cc-465a-ba21-f232bb95843f',
        question: 'How do I post a job opening?',
        answer: 'As an employer, posting a job on JobzMall is one of the many important catalysts in attracting talent to your store. Getting started is very simple. <br><br> First, go to the jobs page here on your employer dashboard. After that click the "+ Add Job" button. The Job Studio will then appear, where you can enter all of the information about your job. Simply fill in this information, select a Job Tier, and then click "Post Job". You may need to enter payment information if we don\'t already have it on file. You can also use PayPal to checkout.'
    },
    {
        id: '0fcece82-1691-4b98-a9b9-b63218f9deef',
        categoryId: '28924eab-97cc-465a-ba21-f232bb95843f',
        question:
            'How can I search for talent and source candidates on JobzMall?',
        answer: 'At JobzMall, you will experience a complete reimagining of the candidate sourcing process. At Talent Bank, you are able to utilize advanced filters to fine tune your preference and watch video resumes right away. Instead of reading through an unstructured resume, you will simply see the candidates past experiences, watch them answer their Video Resume Questions, and simply contact them with one click. You can send a message, schedule a video meeting or send a prescreening questionnaire and ask additional questions.'
    },
    {
        id: '2e6971cd-49d5-49f1-8cbd-fba5c71e6062',
        categoryId: '28924eab-97cc-465a-ba21-f232bb95843f',
        question: 'Can we bring over job openings from our career site?',
        answer: 'Absolutely. On your Jobs tab, simply click on the ‘Transfer Jobs’ button at the top right. Our Jobz Engine will automatically transfer your jobs from the career site that you determine. You can either transfer your jobs one-time, or with Continuous Sync your JobzMall account will update your jobs from your career site daily. On average the transfer takes less than 12 hours.'
    },
    {
        id: '974f93b8-336f-4eec-b011-9ddb412ee828',
        categoryId: '28924eab-97cc-465a-ba21-f232bb95843f',
        question: 'What is a Storefront on JobzMall?',
        answer: 'JobzMall is built as a virtual shopping mall where talent meets work. Organizations (companies, startups, nonprofits, colleges, agencies) host virtual stores on JobzMall, for visitors to come in and explore. <br><br> We believe any organization is more than just a job post. They are a brand, a culture. Impress your visitors with videos and announcements. <br><br> Your company is your store window. Make a great impression on each and every visitor. For more tips on how to make the most of your store you can live chat with your dedicated success agent now.'
    },
    {
        id: '5d877fc7-b881-4527-a6aa-d39d642feb23',
        categoryId: '28924eab-97cc-465a-ba21-f232bb95843f',
        question: 'What are Async Interviews?',
        answer: 'The fastest way to Interview at scale. Boost your candidate experience with pre-recorded Interview questions, to build better relationships and reduce ghosting. Simply explain the role, introduce future colleagues, and answer any questions they may have with video. What better way to attract the best candidates. Candidates will then submit their interviews in one session. This way instead of receiving a text-based resume, you already have conducted your first Interview without additional effort. We strive to maximize your time with work that matters.'
    },

    // Employer: Finding Talent
    {
        id: '41a32667-aab2-4975-aab0-8b658f549805',
        categoryId: 'ebcfe17f-4ae7-41d9-85d1-0cd37309a735',
        question: 'Candidate Types',
        answer: 'JobzMall’s talent marketplace nurtures the world’s most engaged and qualified job seekers ready to interview. <br><br> As an employer, you will have a few different candidate types at your arsenal. First, you will have active applicants who have applied to your job openings, or have shown interest in working in your organizations via Direct Apply. You can message, send pre-screening questionnaires, manage interview meetings and so much more.<br><br>Secondly, you can discover new talent on Talent Bank. Talent Bank is a revolutionary way of sourcing active candidates that have not applied to your organizations. You can simply watch their Video Resumes, and Unlock their full profiles and reach out to them.'
    },
    {
        id: 'ae4d9ca7-181b-4a64-9638-ae12de2cc08c',
        categoryId: 'ebcfe17f-4ae7-41d9-85d1-0cd37309a735',
        question: 'What is Direct Apply?',
        answer: 'Direct Apply is a revolutionary way to apply to organizations developed by JobzMall. Direct applications allow candidates to apply directly to organizations, regardless of a specific job openings. This way, organizations can grow their talent pipeline, and nurture warm leads<br><br>In the old days, organizations promoted job seekers to shoot over their resumes to a generic HR email to grow their talent reach. Today, Direct Apply is addressing that problem with a much more elegant solution.'
    },
    {
        id: '76738327-820e-4b9d-8e31-90762dce5c06',
        categoryId: 'ebcfe17f-4ae7-41d9-85d1-0cd37309a735',
        question: 'Discovering Talent Bank',
        answer: 'At Talent Bank, we have developed the world’s best candidate sourcing experience for you to grow your organization. Instead of fishing for resumes and hoping for the best, you can simply watch Video Resumes of active and qualified job seekers ready to interview.'
    },
    {
        id: '57502660-687a-4b22-a7a4-ecfd406d0182',
        categoryId: 'ebcfe17f-4ae7-41d9-85d1-0cd37309a735',
        question: 'Candidate Sourcing',
        answer: "JobzMall nurtures the world’s largest video-based talent network in the world. We bring candidates to the JobzMall marketplace through a variety of methods, currently serving over 4M+ active job seekers.<br><br>We started in 2016, initially built for the young job seekers entering into the workforce. If you think from the perspective of a 22-year old, a Resume is not your friend. You don’t have a past! More importantly, a company is not going to hire a young job seeker because of their past, they are going to hire because of their potential, and adaptability. We believed that video was the best interface to promote this dialogue.<br><br>Since then, we have grown our talent marketplace into more senior roles, and with COVID-19 disrupting the workforce landscape, our human first philosophy has enabled us to become the world's largest video based talent marketplace. But more importantly, a community."
    },

    // Employer: Integrations
    {
        id: '4a86de8f-f631-463f-8fa7-6e218d080b6e',
        categoryId: 'd2159386-7df3-47f1-9137-bc7cdee5f932',
        question: 'Google Calendar',
        answer: 'You can integrate your Google Calendar to JobzMall to manage your meetings automatically across channels. If you need further help please do not hesitate to contact us with live chat and a success agent will be on to help you get you going!'
    },
    {
        id: '8d6d8641-8cc2-4ca1-b815-70cba3787799',
        categoryId: 'd2159386-7df3-47f1-9137-bc7cdee5f932',
        question: 'Microsoft Outlook Calendar',
        answer: 'You can integrate your Microsoft Outlook Calendar to JobzMall to manage your meetings automatically across channels. If you need further help please do not hesitate to contact us with live chat and a success agent will be on to help you get you going!'
    },
    {
        id: 'bdedbedc-cddf-47e1-9d88-cdb959b25b2e',
        categoryId: 'd2159386-7df3-47f1-9137-bc7cdee5f932',
        question: 'ATS Options',
        answer: 'JobzMall currently integrates with four ATS partners: Greenhouse, Lever and Workable, and Workday. Please Integrations Center for more details. If you need further help or need an integration with a different vendor, do not hesitate to contact us as we will be happy to assist you.'
    },
    {
        id: 'c3f774cd-d5f9-4783-9fe9-a770326068d2',
        categoryId: 'd2159386-7df3-47f1-9137-bc7cdee5f932',
        question: 'Zoom Video',
        answer: 'As the world’s largest video-first talent marketplace, JobzMall integrates seamlessly with Zoom to have you manage your video meetings. You can integrate your Zoom account to JobzMall to manage your meetings automatically across channels. If you need further help please do not hesitate to contact us with live chat and a success agent will be on to help you get you going!'
    },
    {
        id: '19ae1fa6-b748-482c-bff3-0584a86060b7',
        categoryId: 'd2159386-7df3-47f1-9137-bc7cdee5f932',
        question: 'Deel',
        answer: 'JobzMall’s integration with Deel provides a seamless experience for businesses looking to hire remote workers from around the world. With this integration, you can access Deel’s comprehensive suite of remote employment and payroll services directly from JobzMall’s platform. Through the integration, you can post job listings on JobzMall and easily manage the entire hiring process from start to finish. You can use Deel’s services to create employment contracts, manage compliance and legal requirements, process payments, and administer benefits for their remote workers, all from one centralized platform. This integration saves businesses time and resources by streamlining the hiring process and simplifying the complexities of remote employment. It also enables you to tap into a global talent pool and hire the best candidates for their specific needs, without having to worry about the logistics of employing workers in different countries.'
    },
    // Employer: Security
    {
        id: '6ed98537-1217-42a2-8094-0843f85c8d43',
        categoryId: '29d7102f-f35a-4f74-805b-4f8e0c0f0a81',
        question: 'SSO & 2FA',
        answer: 'Customer trust and data security are critical to everything we do at JobzMall. SAML Single Sign-on (SSO) allows you to authenticate users in your own systems without requiring them to enter additional login credentials. If you’re using password-based authentication, you can turn on 2-factor authentication (2FA) for all your members.'
    },
    {
        id: 'c207250e-65b1-4c18-a4ec-df16111d5f5c',
        categoryId: '29d7102f-f35a-4f74-805b-4f8e0c0f0a81',
        question: 'Permissions',
        answer: 'We enable permission levels within the app to be set for your teammates. Permissions can be set to include app settings, billing, user data or the ability to send or edit messages.'
    },
    {
        id: 'b2d91402-9778-4f33-91f8-16dca4f904a0',
        categoryId: '29d7102f-f35a-4f74-805b-4f8e0c0f0a81',
        question: 'Encryption',
        answer: 'All data sent to or from JobzMall is encrypted in transit using 256 bit encryption. Our API and application endpoints are TLS/SSL only and score an “A+” rating on Qualys SSL Labs’ tests. This means we only use strong cipher suites and have features such as HSTS and Perfect Forward Secrecy fully enabled. We also encrypt data at rest using an industry-standard AES-256 encryption algorithm.'
    },
    {
        id: '2f0292b7-3a77-47a5-9d9f-5fee00bc0d7b',
        categoryId: '29d7102f-f35a-4f74-805b-4f8e0c0f0a81',
        question: 'Incident Response',
        answer: 'JobzMall implements a protocol for handling security events which includes escalation procedures, rapid mitigation and post-mortem. All employees are informed of our policies.'
    },
    {
        id: 'f40d4b94-de23-48cf-b3ff-296a3bd8ed9e',
        categoryId: '29d7102f-f35a-4f74-805b-4f8e0c0f0a81',
        question: 'Training',
        answer: 'All JobzMall employees complete Security and Awareness training annually.'
    },
    {
        id: '06c56f30-741d-4d6a-86d0-821c785e7f00',
        categoryId: '29d7102f-f35a-4f74-805b-4f8e0c0f0a81',
        question: 'Policies',
        answer: 'JobzMall has developed a comprehensive set of security policies covering a range of topics. These policies are updated frequently and shared with all employees.'
    },
    // Employer: About
    {
        id: 'd860d1ef-4331-4511-b159-9b995a32200a',
        categoryId: 'fdad3c69-3a69-4ef0-83fa-7a92438a6a6a',
        question: 'Benefits of JobzMall',
        answer: 'JobzMall is the simplest, most efficient way to hire the best talent. We provide you access to a highly qualified pool of candidates — both full-time and contract — that are ready to interview now. Each of these individuals are considering new roles and have been screened through a combination of proprietary algorithms and an in-house curation team.<br><br>Our platform gives you insight into candidates’ professional background, work preferences, and compensation, so you have the information you need to make better decisions, adjust your offers in real-time if needed, and hire the best fit for your team.'
    },
    {
        id: '82f602c5-88b7-4216-9af2-712caa057570',
        categoryId: 'fdad3c69-3a69-4ef0-83fa-7a92438a6a6a',
        question: 'JobzMall Pricing',
        answer: "Whether you're hiring two or two hundred, JobzMall will help you address your unique hiring needs. Your Account Manager will discuss the best option with you and custom pricing is also available.<br><br>JobzMall is free for candidates to use."
    },
    {
        id: 'ba1f3703-2e32-4b94-848b-bb97015aa064',
        categoryId: 'fdad3c69-3a69-4ef0-83fa-7a92438a6a6a',
        question: 'JobzMall Terms of Service',
        answer: 'You can review JobzMall’s Terms Of Service here: <a href="https://www.jobzmall.com/legal/terms" target="_blank">https://www.jobzmall.com/legal/terms<a><br><br>Please never hesitate to reach out to us with any questions.'
    },
    {
        id: '1e164728-3343-4cdb-9ab2-e7c386ffab77',
        categoryId: 'fdad3c69-3a69-4ef0-83fa-7a92438a6a6a',
        question: 'JobzMall Compliance',
        answer: 'JobzMall System and Organization Controls (SOC) Reports are independent third-party examination reports that demonstrate how Hired achieves key compliance controls and objectives. The purpose of these reports is to help you and your compliance team understand the JobzMall controls pertaining to security processes and the platform. A mutual non-disclosure agreement (MNDA) is required to review the JobzMall SOC 2 Report. If you would like to learn more or have an interest in reviewing the complete Report, contact us.'
    }
];
export const guideCategories = [
    {
        id: '0ee72de7-49c0-4880-9e89-b72a4edd6a81',
        slug: 'getting-started',
        title: 'Getting Started'
    },
    {
        id: '07b8421f-20bf-45b6-90ee-169ebe3a5bcc',
        slug: 'projects',
        title: 'Projects'
    },
    {
        id: 'c88a1f54-360a-4b9b-a54b-2f92b7a1f63b',
        slug: 'settings',
        title: 'Settings'
    },
    {
        id: '7b25b38c-1ab3-4474-8569-65b3ea232add',
        slug: 'payments',
        title: 'Payments'
    },
    {
        id: '41fdf071-aec4-49de-9dd4-b4f746596928',
        slug: 'your-account',
        title: 'Your Account'
    }
];
export const guides = [
    // Getting started
    {
        id: 'a008ffa3-7b3f-43be-8a8f-dbf5272ed2dd',
        categoryId: '0ee72de7-49c0-4880-9e89-b72a4edd6a81',
        slug: 'what-is-this-app',
        title: 'What is this app?',
        subtitle:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt'
    },
    {
        id: '7643d388-12ab-4025-a2f1-5045ac7b1c4c',
        categoryId: '0ee72de7-49c0-4880-9e89-b72a4edd6a81',
        slug: 'start-using-the-app',
        title: 'Start using the app',
        subtitle:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt'
    },
    {
        id: '1fecee67-c4b4-413a-b0f2-949dcab73249',
        categoryId: '0ee72de7-49c0-4880-9e89-b72a4edd6a81',
        slug: 'signing-in-to-the-dashboard',
        title: 'Signing in to the dashboard',
        subtitle:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt'
    },
    {
        id: 'd2e2ea8f-5298-4ba2-898b-afc60c064bba',
        categoryId: '0ee72de7-49c0-4880-9e89-b72a4edd6a81',
        slug: 'navigating-within-the-app',
        title: 'Navigating within the app',
        subtitle:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt'
    },
    // Projects
    {
        id: 'f2592886-11b8-4b56-baab-96802c2ed93e',
        categoryId: '07b8421f-20bf-45b6-90ee-169ebe3a5bcc',
        slug: 'creating-a-project',
        title: 'Creating a project',
        subtitle:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt'
    },
    {
        id: '9ec3f4b9-a355-4f57-9e93-efa8611cc1c9',
        categoryId: '07b8421f-20bf-45b6-90ee-169ebe3a5bcc',
        slug: 'renaming-a-project',
        title: 'Renaming a project',
        subtitle:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt'
    },
    {
        id: '1bc6e7f9-b046-4f4f-9b18-741c9d5429f6',
        categoryId: '07b8421f-20bf-45b6-90ee-169ebe3a5bcc',
        slug: 'displaying-a-project',
        title: 'Displaying a project',
        subtitle:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt'
    },
    {
        id: 'a005d5f1-938d-45c5-8ed4-d0cf8d02e533',
        categoryId: '07b8421f-20bf-45b6-90ee-169ebe3a5bcc',
        slug: 'deleting-a-project',
        title: 'Deleting a project',
        subtitle:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt'
    },
    {
        id: '43837279-dce2-4dc0-beac-30b5ba829f14',
        categoryId: '07b8421f-20bf-45b6-90ee-169ebe3a5bcc',
        slug: 'changing-the-visibility-of-a-project',
        title: 'Changing the visibility of a project',
        subtitle:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt'
    },
    {
        id: '4cf5a435-eaa0-463c-8d2b-efde193c7fb3',
        categoryId: '07b8421f-20bf-45b6-90ee-169ebe3a5bcc',
        slug: 'adding-media-to-a-project',
        title: 'Adding media to a project',
        subtitle:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt'
    },
    {
        id: 'cd3fb87e-e138-4721-9e29-a5c751bfd949',
        categoryId: '07b8421f-20bf-45b6-90ee-169ebe3a5bcc',
        slug: 'removing-a-media-from-a-project',
        title: 'Removing a media from a project',
        subtitle:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt'
    },
    {
        id: 'f26205c6-882e-4713-b067-c73758b45551',
        categoryId: '07b8421f-20bf-45b6-90ee-169ebe3a5bcc',
        slug: 'cropping-a-media',
        title: 'Cropping a media',
        subtitle:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt'
    },
    // Settings
    {
        id: '1cbdeaeb-bbf1-4d04-b43d-f37b55e6a229',
        categoryId: 'c88a1f54-360a-4b9b-a54b-2f92b7a1f63b',
        slug: 'general-settings',
        title: 'General settings',
        subtitle:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt'
    },
    {
        id: '98de7d4a-2ca2-4d47-bbe6-083ed26467db',
        categoryId: 'c88a1f54-360a-4b9b-a54b-2f92b7a1f63b',
        slug: 'project-settings',
        title: 'Project settings',
        subtitle:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt'
    },
    {
        id: '145f497c-1fdb-47b5-a6c1-31f856403571',
        categoryId: 'c88a1f54-360a-4b9b-a54b-2f92b7a1f63b',
        slug: 'media-settings',
        title: 'Media settings',
        subtitle:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt'
    },
    {
        id: '0a007f59-a5ea-4875-991d-f22d6fd69898',
        categoryId: 'c88a1f54-360a-4b9b-a54b-2f92b7a1f63b',
        slug: 'domain-settings',
        title: 'Domain settings',
        subtitle:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt'
    },
    {
        id: '4707c8eb-31f9-415c-bd07-86f226c75feb',
        categoryId: 'c88a1f54-360a-4b9b-a54b-2f92b7a1f63b',
        slug: 'privacy-settings',
        title: 'Privacy settings',
        subtitle:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt'
    },
    // Payments
    {
        id: 'c771bf0a-1e0c-4b6d-af7e-189e10cc6fb8',
        categoryId: '7b25b38c-1ab3-4474-8569-65b3ea232add',
        slug: 'subscriptions',
        title: 'Subscriptions',
        subtitle:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt'
    },
    {
        id: '3d7150d2-feb3-4f20-bd3f-8e525cef77a4',
        categoryId: '7b25b38c-1ab3-4474-8569-65b3ea232add',
        slug: 'discounts',
        title: 'Discounts',
        subtitle:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt'
    },
    {
        id: '79239bc4-4fb5-428b-b30d-62c5289b061d',
        categoryId: '7b25b38c-1ab3-4474-8569-65b3ea232add',
        slug: 'payment-methods',
        title: 'Payment methods',
        subtitle:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt'
    },
    {
        id: '8d68c5e6-5404-450c-9d5f-d9800c164041',
        categoryId: '7b25b38c-1ab3-4474-8569-65b3ea232add',
        slug: 'overdue-payments',
        title: 'Overdue payments',
        subtitle:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt'
    },
    // Your account
    {
        id: '60df0d4c-dda1-439c-bd44-179c57a7597d',
        categoryId: '41fdf071-aec4-49de-9dd4-b4f746596928',
        slug: 'changing-your-username',
        title: 'Changing your username',
        subtitle:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt'
    },
    {
        id: '0a9c3321-1db3-42bc-92b6-7e257368123e',
        categoryId: '41fdf071-aec4-49de-9dd4-b4f746596928',
        slug: 'changing-your-email',
        title: 'Changing your email',
        subtitle:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt'
    },
    {
        id: '80ba5106-5f9c-4ed7-b8f3-8544035e3095',
        categoryId: '41fdf071-aec4-49de-9dd4-b4f746596928',
        slug: 'changing-your-password',
        title: 'Changing your password',
        subtitle:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt'
    },
    {
        id: 'db2e97a6-d657-4e9d-9b6c-5f213ea3301c',
        categoryId: '41fdf071-aec4-49de-9dd4-b4f746596928',
        slug: 'closing-your-account',
        title: 'Closing your account',
        subtitle:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt'
    },
    {
        id: '3374c887-2fb7-4223-9f40-7f2cbbf76795',
        categoryId: '41fdf071-aec4-49de-9dd4-b4f746596928',
        slug: 'account-limits',
        title: 'Account limits',
        subtitle:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt'
    },
    {
        id: 'cc65f92a-7d46-4557-b15b-6f8f59a60576',
        categoryId: '41fdf071-aec4-49de-9dd4-b4f746596928',
        slug: 'two-factor-authentication',
        title: 'Two factor authentication',
        subtitle:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt'
    }
];

// Since we only have one content for the demo, we will
// use the following mock-api on every request for every guide.
export const guideContent = `
<h2>Header Level 2</h2>

<p>
    <strong>Pellentesque habitant morbi tristique</strong> senectus et netus et malesuada fames ac turpis egestas. Vestibulum tortor quam, feugiat vitae, ultricies eget, tempor sit
    amet, ante. Donec eu libero sit amet quam egestas semper. <em>Aenean ultricies mi vitae est.</em> Mauris placerat eleifend leo. Quisque sit amet est et sapien ullamcorper
    pharetra. Vestibulum erat wisi, condimentum sed, <code>commodo vitae</code>, ornare sit amet, wisi. Aenean fermentum, elit eget tincidunt condimentum, eros ipsum rutrum orci,
    sagittis tempus lacus enim ac dui. <a href="#"
                                          class="link">Donec non enim</a>
    in turpis pulvinar facilisis. Ut felis.
</p>

<p>
    Orci varius natoque penatibus et magnis dis <em>parturient montes</em>, nascetur ridiculus mus. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos
    himenaeos. Curabitur vitae sagittis odio. <mark>Suspendisse</mark> ullamcorper nunc non pellentesque laoreet. Curabitur eu tortor id quam pretium mattis. Proin ut quam velit.
</p>

<h3>Header Level 3</h3>

<img src="assets/images/pages/help-center/image-1.jpg">
<p class="text-secondary">
    <em>Nullam sagittis nulla in diam finibus, sed pharetra velit vestibulum. Suspendisse euismod in urna eu posuere.</em>
</p>

<h4>Header Level 4</h4>

<blockquote>
    <p>
        Blockquote. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus magna. Cras in mi at felis aliquet congue. Ut a est eget ligula molestie gravida. Curabitur
        massa. Donec eleifend, libero at sagittis mollis, tellus est malesuada tellus, at luctus turpis elit sit amet quam. Vivamus pretium ornare est.
    </p>
    <footer>
        Brian Hughes
    </footer>
</blockquote>

<ol>
    <li>Ordered list</li>
    <li>Lorem ipsum dolor sit amet, consectetuer adipiscing elit.</li>
    <li>Aliquam tincidunt mauris eu risus.</li>
</ol>

<h5>Header Level 5</h5>

<ul>
    <li>Unordered list</li>
    <li>Lorem ipsum dolor sit amet, consectetuer adipiscing elit.</li>
    <li>Aliquam tincidunt mauris eu risus.</li>
</ul>

<pre><code>#header h1 a {
    display: block;
    width: 300px;
    height: 80px;
}</code></pre>

<h6>Header Level 6</h6>

<dl>
    <dt>Definition list</dt>
    <dd>
        Quisque sit amet risus enim. Aliquam sit amet interdum justo, at ultricies sapien. Suspendisse et semper urna, in gravida eros. Quisque id nibh iaculis, euismod urna sed,
        egestas nisi. Donec eros metus, congue a imperdiet feugiat, sagittis nec ipsum. Quisque dapibus mollis felis non tristique.
    </dd>

    <dt>Definition list</dt>
    <dd>
        Ut auctor, metus sed dapibus tempus, urna diam auctor odio, in malesuada odio risus vitae nisi. Etiam blandit ante urna, vitae placerat massa mollis in. Duis nec urna ac
        purus semper dictum ut eget justo. Aenean non sagittis augue. Sed venenatis rhoncus enim eget ornare. Donec viverra sed felis at venenatis. Mauris aliquam fringilla nulla,
        sit amet congue felis dignissim at.
    </dd>
</dl>`;
