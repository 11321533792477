import { Injectable } from '@angular/core';

import * as dayjs from 'dayjs';

import {
    Link,
    LinkAdapter,
    Location,
    LocationAdapter,
    Media,
    MediaAdapter,
    SEO,
    SEOAdapter
} from '@jobzmall/models';

import { Adapter } from './adapter';

export class Organization {
    constructor(
        public id?: number,
        public name?: string,
        public followers_count?: number,
        public jobs_count?: number,
        public posts_count?: number,
        public media_count?: number,
        public members_count?: number,
        public seo?: SEO,
        public slug?: string,
        public status?: OrganizationStatus,
        public user_state?: UserOrganizationState,
        public visible?: boolean,
        public featured_video_id?: number,
        public store_size?: OrganizationSize,
        public business_phone?: string,
        public color?: string,
        public description?: string,
        public founded?: number,
        public headquarters?: string,
        public image?: Media,
        public background?: Media,
        public featured_video?: Media,
        public limits?: any,
        public store_size_id?: number,
        public application_count?: number,
        public website?: string,
        public created_at?: Date,
        public updated_at?: Date,
        public featured_on?: Array<any>,
        public enterpriseAvailable?: boolean,
        public top?: boolean,
        public json_ld?: any,
        public business_email?: string,
        public aliases?: Array<any>,
        public has_account?: boolean,
        public links?: Array<Link>,
        public twitter_handle?: string,
        public video?: Media,
        public stories_count?: number,
        public parent_id?: number,
        public parent?: any,
        public has_slack_integration?: boolean,
        public has_talent_pools?: boolean,
        public background_id?: number,
        public two_factor?: boolean,
        public tb_unlocks_count?: number,
        public is_learning_provider?: boolean,
        public value_badges?: Array<any>,
        public lists?: Array<any>,
        public reviewed?: boolean,
        public public_interviews_count?: number,
        public recent_public_interview?: string,
        public show_member_highlights?: boolean,
        public upcoming_interviews_count?: number,
        public candidates_count?: number,
        public industry_id?: number,
        public industry?: any,
        public location?: Location,
        public youtube_id?: string,
        public ss?: number,
        public pc?: boolean,
        public videos?: Array<Media>,
        public faqs_count?: number,
        public has_review_summary?: boolean,
        public last_job_added_at? : Date,
        public review_count?: number,
        public rating?: number,
        public ja_ratio?: number
    ) {}
}

@Injectable({
    providedIn: 'root'
})
export class OrganizationAdapter implements Adapter<Organization> {
    constructor(
        private _linkAdapter: LinkAdapter,
        private _mediaAdapter: MediaAdapter,
        private _organizationSizeAdapter: OrganizationSizeAdapter,
        private _userOrganizationStateAdapter: UserOrganizationStateAdapter,
        private _seoAdapter: SEOAdapter,
        private _locationAdapter: LocationAdapter
    ) {}
    adapt(item: any): Organization {
        let result = new Organization(
            item.id,
            item.name,
            item.followers_count,
            item.jobs_count,
            item.posts_count,
            item.media_count,
            item.members_count,
            item.seo ? this._seoAdapter.adapt(item.seo) : undefined,
            item.slug,
            item.status,
            this._userOrganizationStateAdapter.adapt(item),
            item.visible,
            item.featured_video_id,
            item.store_size
                ? this._organizationSizeAdapter.adapt(item.store_size)
                : undefined,
            item.business_phone,
            item.color,
            item.description,
            item.founded,
            item.headquarters,
            item.image ? this._mediaAdapter.adapt(item.image) : undefined,
            item.background
                ? this._mediaAdapter.adapt(item.background)
                : undefined,
            item.featured_video
                ? this._mediaAdapter.adapt(item.featured_video)
                : undefined,
            item.limits,
            item.store_size_id,
            item.application_count || item.applications_count,
            item.website,
            item.created_at ? dayjs(item.created_at).toDate() : undefined,
            item.updated_at ? dayjs(item.updated_at).toDate() : undefined,
            item.featured_on,
            item.enterpriseAvailable,
            item.top,
            item.json_ld,
            item.business_email,
            item.aliases,
            item.has_account,
            item.links
                ? item.links.map((link: any) => this._linkAdapter.adapt(link))
                : [],
            item.twitter_handle ? item.twitter_handle : undefined,
            item.video
                ? this._mediaAdapter.adapt(item.video)
                : item.featured_video
                ? this._mediaAdapter.adapt(item.featured_video)
                : undefined,
            item.stories_count,
            item.parent_id,
            item.parent,
            item.has_slack_integration,
            item.has_talent_pools,
            item.background_id,
            item.two_factor,
            item.tb_unlocks_count,
            item.is_learning_provider,
            item.value_badges,
            item.lists,
            item.reviewed,
            item.public_interviews_count,
            item.recent_public_interview,
            item.show_member_highlights,
            item.upcoming_interviews_count,
            item.candidates_count,
            item.industry_id,
            item.industry,
            item.location
                ? this._locationAdapter.adapt(item.location)
                : undefined,
            item.youtube_id,
            item.ss,
            item.pc,
            item.videos
                ? item.videos.map((v) => this._mediaAdapter.adapt(v))
                : [],
            item.faqs_count,
            item.has_review_summary,
            item.last_job_added_at ? dayjs(item.last_job_added_at).toDate() : undefined,
            item.review_count,
            item.rating,
            item.ja_ratio
        );
        return result;
    }

    anon(item: Organization) {
        return {
            id: item.id,
            name: item.name,
            slug: item.slug,
            store_size_id:
                item.store_size_id ||
                (item.store_size ? item.store_size.id : null),
            color: item.color,
            founded: item.founded,
            headquarters: item.headquarters,
            description: item.description,
            image: item.image,
            website: item.website,
            status: item.status,
            business_email: item.business_email,
            business_phone: item.business_phone,
            aliases: item.aliases,
            links: item.links,
            parent_id: item.parent_id,
            has_slack_integration: item.has_slack_integration,
            has_talent_pools: item.has_talent_pools,
            background_id: item.background_id,
            is_learning_provider: item.is_learning_provider,
            value_badges: item.value_badges,
            show_member_highlights: item.show_member_highlights,
            industry_id: item.industry_id,
            youtube_id: item.youtube_id,
            pc: item.pc
        };
    }
}

export class UserOrganizationState {
    constructor(public applied?: boolean, public followed?: boolean) {}
}
@Injectable({
    providedIn: 'root'
})
export class UserOrganizationStateAdapter
    implements Adapter<UserOrganizationState>
{
    adapt(item: any): UserOrganizationState {
        return new UserOrganizationState(item.applied, item.followed);
    }
}

export class OrganizationSize {
    constructor(public id: number, public name: string) {}
}
@Injectable({
    providedIn: 'root'
})
export class OrganizationSizeAdapter implements Adapter<OrganizationSize> {
    adapt(item: any): OrganizationSize {
        return new OrganizationSize(item.id, item.name);
    }
}

export interface IOrganizationSuggestion {
    domain: string;
    name: string;
    logo: string;
}
@Injectable({
    providedIn: 'root'
})
export class OrganizationSuggestionToOrganizationAdapter
    implements Adapter<Organization>
{
    constructor(private _organizationAdapter: OrganizationAdapter) {}
    adapt(item: IOrganizationSuggestion): Organization {
        let partial = {
            name: item.name,
            links: [
                {
                    type: 'website',
                    url: item.domain
                }
            ],
            image: {
                type: 'image',
                full_path: item.logo
            }
        };
        return this._organizationAdapter.adapt(partial);
    }
}

export enum OrganizationMediaType {
    VIDEO = 'video',
    IMAGE = 'image'
}
export enum OrganizationStatus {
    UNCLAIMED = 'unclaimed',
    ARCHIVED = 'archived',
    VERIFIED = 'verified',
    UNVERIFIED = 'unverified'
}

export const BILLING_RESTRICTIONS = {
    jobs_count: {
        title: 'Upgrade Your Plan',
        message:
            'You have reached the active job limit (%1$s total) on your plan.'
    },
    members_count: {
        title: 'Upgrade Your Plan',
        message:
            'You have reached the maximum amount of team members (%1$s total) you can have on your plan.'
    }
};
